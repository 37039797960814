
  import { Component } from 'vue-property-decorator'
  import { TimelineBase } from '@/components/core/view/TimelineBase'

@Component
  export default class GTimelineItemText extends TimelineBase {
    render (h) {
      return h(
        'p',
        {
          class: [
            'text-caption',
            'primary-text',
            'text--darken-4',
            'pa-0',
          ],
        },
        [h(
          'span',
          {
            class: 'font-weight-bold blue--text text--darken-3',
          },
          [this.label]
        ), ' ', this.value]
      )
    }
  }
